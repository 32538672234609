import React from "react"
import Cards from "../components/Cards"
import poster from "../images/eventPics/collectiveposter.jpg"
import Layout from "../components/Layout"
import banner from "../images/banner/dj.jpg"
import { Button } from "../styles/ui"
import Article from "../components/Article"
import MapEvent from "../components/MapEvent"
import TrackVisibility from "react-on-screen"
import { FadeUp, CenteredBox } from "../styles/ui"
import { ticketLink } from "../content/content"
import fullsize1 from "../images/eventPics/kevin.jpg"
import fullsize2 from "../images/eventPics/alex.jpg"
import fullsize3 from "../images/eventPics/rauw.jpg"
import fullsize4 from "../images/eventPics/hyper.jpg"
import Gallery from "../components/Gallery"
import IconList from "../components/IconList"
import {
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaRocket,
  FaGift,
  FaSoundcloud,
  FaHeadphones,
  FaCrow,
  FaTicketAlt,
  FaMusic,
  FaFacebook,
} from "react-icons/fa"
import Section from "../components/Section"
import { darken } from "polished"
import theme from "../styles/theme"
import Banner from "../components/Banner"
import SEOpt from "../components/SEOpt"
import WrapFadeUp from "../components/WrapFadeUp"
import StickyCTA from "../components/StickyCTA"
import { Link as ScrollLink } from "react-scroll"
import { getMenuHeight } from "../components/NavBar"

const iconListItems = [
  {
    text: "Alex Sharp",
    icon: <FaSoundcloud />,
    link: "https://soundcloud.com/alex_sharp_official",
  },
  {
    text: "Hyper Amsterdam",
    icon: <FaSoundcloud />,
    link: "https://soundcloud.com/gerardkrom",
  },
  {
    text: "Kevin Valentine",
    icon: <FaSoundcloud />,
    link: "https://soundcloud.com/kevinvalentine",
  },
  {
    text: "Rauw",
    icon: <FaSoundcloud />,
  },
]

const galleryItems = [
  {
    alt: "Kevin Valentine",
    fullsize: fullsize1,
    thumbnail: fullsize1,
  },
  {
    alt: "Alex Sharp",
    fullsize: fullsize2,
    thumbnail: fullsize2,
  },
  {
    alt: "Rauw",
    fullsize: fullsize3,
    thumbnail: fullsize3,
  },
  {
    alt: "Hyper",
    fullsize: fullsize4,
    thumbnail: fullsize4,
  },
]

const bannerImage = {
  src: banner,
  altText: "Rocketship Events",
}

const addIcon = (icon, text) => (
  <>
    {icon} {text}
  </>
)

const pricing = [
  {
    title: "7,50",
    text: addIcon(
      <FaCrow
        style={{
          fontSize: "1.5em",
          verticalAlign: "-.2em",
          marginRight: ".3em",
        }}
      />,
      "Early bird"
    ),
  },
  {
    title: "10",
    text: addIcon(
      <FaCrow
        style={{
          fontSize: "1.5em",
          verticalAlign: "-.2em",
          marginRight: ".3em",
        }}
      />,
      "Regular bird"
    ),
  },
  {
    title: "15",
    text: addIcon(
      <FaTicketAlt
        style={{
          fontSize: "1.5em",
          verticalAlign: "-.2em",
          marginRight: ".3em",
        }}
      />,
      "Door sale"
    ),
  },
]

const Events = () => {
  return (
    <Layout>
      <SEOpt title="Rocketship Events" />
      <Banner {...bannerImage} />
      <Section background={darken(0.03, theme.bg)}>
        <Article>
          <WrapFadeUp>
            <Button
              as="a"
              className="price-tag-btn"
              href={ticketLink}
              target="_blank"
            >
              Tickets from &euro;7,50
            </Button>
            <h1>Rocketship Collective Event</h1>
          </WrapFadeUp>
          <WrapFadeUp>
            <h3 style={{ marginBottom: "0" }}>
              <FaCalendarAlt className="icon-inline" />
              Saturday, October 23<sup>rd</sup>, 18:00 - 00:00
            </h3>
          </WrapFadeUp>
          <WrapFadeUp>
            <h3 style={{ marginBottom: "0" }}>
              <ScrollLink
                to="mapWrapperSection"
                smooth={true}
                duration={500}
                offset={-getMenuHeight()}
                href="#mapWrapperSection"
              >
                <FaMapMarkerAlt className="icon-inline" />
                Club Panama Amsterdam
              </ScrollLink>
            </h3>
          </WrapFadeUp>
          <WrapFadeUp>
            <h3>
              <a
                href="https://fb.me/e/RTaeLAfv"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook className="icon-inline" />
                View this event on Facebook
              </a>
            </h3>
          </WrapFadeUp>
          <WrapFadeUp>
            <p>
              We are finally back and stronger than ever! The Rocketship has
              joined forces with TECHOPHOBIA, UNDRGRND AND SONORUS to form THE
              ROCKETSHIP COLLECTIVE, and we're having a massive event at one of
              the best clubs in Amsterdam! We plan to spend the night dancing to
              insane techno - so once again, get ready to fly with us!{" "}
              <FaRocket style={{ marginLeft: ".2em" }} />
            </p>
          </WrapFadeUp>
          <WrapFadeUp>
            <h3>
              <FaHeadphones className="icon-inline" /> Line-up in alphabetical
              order
            </h3>
          </WrapFadeUp>
          <ul>
            <WrapFadeUp>
              <li>Alex Sharp</li>
            </WrapFadeUp>
            <WrapFadeUp>
              <li>Hyper Amsterdam</li>
            </WrapFadeUp>
            <WrapFadeUp>
              <li>Kevin Valentine</li>
            </WrapFadeUp>
            <WrapFadeUp>
              <li>Rauw</li>
            </WrapFadeUp>
          </ul>
          <WrapFadeUp>
            <h3>
              <FaGift className="icon-inline" />
              Extra
            </h3>
          </WrapFadeUp>
          <WrapFadeUp>
            <p>
              During the event you can enjoy ebalancing massage - a deep and
              slow type of full-body massage - by{" "}
              <a
                href="https://www.facebook.com/iza.wijffels"
                target="_blank"
                rel="noreferrer"
              >
                Iza Wijvels
              </a>
              . More info:{" "}
              <a
                href="https://www.iamlivinglightly.com/massage"
                target="_blank"
                rel="noreferrer"
              >
                iamlivinglightly.com
              </a>
              .
            </p>
          </WrapFadeUp>
          <WrapFadeUp>
            <h3>
              <FaTicketAlt className="icon-inline" /> Tickets
            </h3>
          </WrapFadeUp>
          <ul>
            <WrapFadeUp>
              <li>Early bird - €7,50</li>
            </WrapFadeUp>
            <WrapFadeUp>
              <li>Regular bird - €10,00</li>
            </WrapFadeUp>
            <WrapFadeUp>
              <li>Door sale - €15,00</li>
            </WrapFadeUp>
          </ul>
          <WrapFadeUp>
            <p>
              Get your tickets here:{" "}
              <a href={ticketLink} target="_blank" rel="noreferrer">
                {ticketLink}
              </a>
              .
            </p>
          </WrapFadeUp>
          <WrapFadeUp>
            <p>
              Questions about this event?{" "}
              <ScrollLink
                to="contact"
                smooth={true}
                duration={500}
                offset={-getMenuHeight()}
                href="#contact"
              >
                Send us a message!
              </ScrollLink>
            </p>
          </WrapFadeUp>
        </Article>
      </Section>
      <Section>
        <CenteredBox>
          <div className="section-head-wrap">
            <TrackVisibility>
              <FadeUp>
                <h2>
                  <FaMusic
                    style={{ marginRight: ".2em", verticalAlign: "-.1em" }}
                  />{" "}
                  Line-Up
                </h2>
              </FadeUp>
            </TrackVisibility>
          </div>
          <IconList items={iconListItems} />
        </CenteredBox>
      </Section>
      <Gallery items={galleryItems} />
      <Section background={darken(0.02, theme.bg)}>
        <CenteredBox>
          <div className="section-head-wrap">
            <WrapFadeUp>
              <h1>Tickets</h1>
            </WrapFadeUp>
            <WrapFadeUp>
              <p className="subhead">
                Get yours{" "}
                <a target="_blank" rel="noreferrer" href={ticketLink}>
                  here
                </a>
              </p>
            </WrapFadeUp>
          </div>
          <Cards items={pricing} pricing />
        </CenteredBox>
      </Section>
      <Section background="#0f0e13" noPaddingBottom id="mapWrapperSection">
        <CenteredBox>
          <div className="section-head-wrap">
            <WrapFadeUp>
              <h1>Location</h1>
            </WrapFadeUp>
            <p className="sub-head">
              <WrapFadeUp>
                <a
                  href="https://panama.nl/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <FaMapMarkerAlt className="icon-inline" />
                  Club Panama Amsterdam
                </a>
                <br />
              </WrapFadeUp>

              <WrapFadeUp>
                <span style={{ fontSize: ".7em", display: "block" }}>
                  Oostelijke Handelskade 4<br />
                  1019 BM Amsterdam
                </span>
              </WrapFadeUp>
            </p>
          </div>
        </CenteredBox>
        <MapEvent />
      </Section>
      <Section background={darken(0.02, theme.bg)}>
        <CenteredBox>
          <div className="section-head-wrap">
            <WrapFadeUp>
              <h1>Poster</h1>
            </WrapFadeUp>
          </div>
          <img src={poster} alt="Event poster" style={{ maxHeight: "80vh" }} />
        </CenteredBox>
      </Section>
      <StickyCTA
        text="Get your ticket"
        buttonText="Shop"
        extHref={ticketLink}
        buttonIcon={<FaCrow />}
      />
    </Layout>
  )
}

export default Events
