const rainbowArray = ({
  steps = 10,
  saturation = 50,
  lightness = 50,
  hueModifier = 0,
}) => {
  const items = [...Array(steps).keys()]
  return items.map(
    item =>
      `hsl(${
        (item * 360) / steps + hueModifier
      }deg, ${saturation}%, ${lightness}%)`
  )
}

export default rainbowArray
