import React from "react"
import styled from "styled-components"
import { Grid, Row, Col } from "@zendeskgarden/react-grid"
import { Button, FadeScale, FadeUp } from "../styles/ui"
import { lighten } from "polished"
import theme from "../styles/theme"
import fonts from "../styles/fonts"
import TrackVisibility from "react-on-screen"
import rainbowArray from "../uitls/rainbowArray"
import { Link } from "gatsby"

const SCards = styled.div`
  .card-wrap {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .card-wrap-inner {
    display: flex;
    height: 100%;
    padding-bottom: 12px;
    box-sizing: border-box;
    width: 100%;
  }
`

const SCard = styled.div`
  padding: 1em;
  text-align: center;
  background: ${lighten(0.05, theme.bg)};
  box-shadow: 1px 2px 3px rgb(0 0 0 / 15%);
  margin-bottom: 0.75em;
  border-top: 7px solid;
  border-color: ${({ accentColor }) => accentColor};
  height: 100%;
  box-sizing: border-box;
  transition: border-color 0.4s, background 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  min-width: ${({ even }) => (even ? "20em" : "10em")};
  cursor: default;
  .img-box {
    background: ${lighten(0.3, theme.bg)};
    border-radius: 100%;
    width: 12em;
    height: auto;
    max-width: 100%;
    padding: 2em;
    box-sizing: border-box;
    margin: auto;
    margin-bottom: 1.8em;
    margin-top: 1.25em;
    transition: background 0.4s, transform 0.4s;
    img {
      margin: auto;
      display: block;
      width: 100%;
      opacity: 0.9;
      transition: all 0.4s;
    }
  }
  .text-box {
    max-width: 18em;
    margin: auto;
  }
  &:hover {
    background: rgb(48 44 53 / 100%);
    border-color: ${({ accentColor }) => accentColor};
    .img-box {
      background: ${({ accentColor }) => accentColor};
    }
    .img-box img {
      opacity: 1;
    }
  }
  h3 {
    font-size: ${props => (!!props.pricing ? "2.2em" : "1.2em")};
    line-height: 1.3;
    color: ${props =>
      props.accentColor ? props.accentColor : props.theme.accent};
    font-family: ${fonts.heading};
    font-weight: 400;
    margin: ${props => (props.pricing ? ".5em 0 .1em" : "0 0 1em")};
  }
  p {
    font-size: 89%;
    margin-bottom: 1.25em;
  }
  button,
  .button {
    font-size: 89%;
  }
`

const Note = styled.div`
  background: rgb(255 255 255 / 6%);
  padding: 0.75em 0.9em;
  margin-top: 1em;
  img {
    display: block;
    max-width: 40px;
    float: left;
    margin-top: 0.1em;
  }
  .text-block {
    text-align: left;
    font-size: 80%;
    padding-left: 50px;
    h4 {
      margin-top: 0;
      margin-bottom: 0.2em;
    }
  }
`

const Cards = ({ items, pricing }) => {
  const colors = rainbowArray({
    steps: items.length,
    saturation: 60,
    lightness: 65,
    hueModifier: 240,
  })
  return (
    <SCards>
      <Grid gutters="sm">
        <Row className="card-row" justifyContent="center">
          {items.map((item, i) => (
            <Col key={item.title}>
              <TrackVisibility partialVisibility className="card-wrap">
                <FadeUp className="card-wrap-inner">
                  <SCard
                    pricing={pricing}
                    className="card"
                    accentColor={colors[i]}
                    even={items.length % 2 === 0}
                  >
                    {!!item.img && (
                      <div className="img-box">
                        <TrackVisibility>
                          <FadeScale>
                            <img src={item.img.src} alt={item.img.altText} />
                          </FadeScale>
                        </TrackVisibility>
                      </div>
                    )}
                    <div className="text-box">
                      <TrackVisibility>
                        <FadeUp>
                          <h3>
                            {pricing && <span>&euro;</span>}
                            {item.title}
                          </h3>
                        </FadeUp>
                      </TrackVisibility>
                      <TrackVisibility>
                        <FadeUp>
                          <p>{item.text}</p>
                        </FadeUp>
                      </TrackVisibility>
                    </div>
                    {item.button && (
                      <TrackVisibility>
                        <FadeUp>
                          {item.button.url && (
                            <Button
                              className="button"
                              as="a"
                              isStretched
                              href={item.button.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.button.text} {item.button.icon}
                            </Button>
                          )}
                          {item.button.to && (
                            <Link to={item.button.to}>
                              <Button className="button" isStretched>
                                {item.button.text} {item.button.icon}
                              </Button>
                            </Link>
                          )}
                        </FadeUp>
                      </TrackVisibility>
                    )}
                    {item.note && (
                      <TrackVisibility>
                        <FadeUp>
                          <Note>
                            <img
                              src={item.note.img.src}
                              alt={item.note.img.altText}
                            />
                            <div className="text-block">
                              <h4>{item.note.title}</h4>
                              {item.note.text}
                            </div>
                          </Note>
                        </FadeUp>
                      </TrackVisibility>
                    )}
                  </SCard>
                </FadeUp>
              </TrackVisibility>
            </Col>
          ))}
        </Row>
      </Grid>{" "}
    </SCards>
  )
}

export default Cards
