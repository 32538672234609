import React, { Component } from "react"
import GoogleMapReact from "google-map-react"
import styled from "styled-components"
import theme from "../styles/theme"
import { getMenuHeight } from "./NavBar"

const SMarker = styled.div`
  background: ${theme.secondaryAccent};
  color: rgba(255, 255, 255, 0.5);
  padding: 1em;
  box-sizing: border-box;
  width: 160px;
  margin-top: -70px;
  h3 {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  p {
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  a {
    color: rgb(255 255 255 / 80%);
    &:hover {
      color: ${theme.accent};
    }
  }
  &:after {
    content: "";
    border: 10px solid transparent;
    border-top-color: ${theme.secondaryAccent};
    position: absolute;
    bottom: -53px;
    left: 0;
  }
`

const MapSection = styled.section`
  height: calc(75vh - ${getMenuHeight}px);
  width: 100%;
  iframe {
    outline: none;
  }
`

const MapMarker = ({ text }) => (
  <SMarker>
    <h3>Club Panama Amsterdam</h3>
    <p>
      Oostelijke Handelskade 4
      <br />
      1019 BM Amsterdam
    </p>
    <a
      href="https://www.google.com/maps/place/Club+Panama+Amsterdam/@52.375,4.9283113,17z/data=!3m1!4b1!4m5!3m4!1s0x47c6090f44305d21:0x9d1dbff1ba1b068a!8m2!3d52.375!4d4.9305"
      target="_blank"
      rel="noopener noreferrer"
    >
      View on Google Maps &raquo;
    </a>
  </SMarker>
)

const coordinates = {
  lat: 52.375167,
  lng: 4.930446,
}

const mapStyles = {
  styles: [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36,
        },
        {
          color: "#101012",
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#000000",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#2b2e37",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#2b2e37",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#2b2e37",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#2b2e37",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#2b2e37",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#1c1a1e",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#2f3035",
        },
        {
          visibility: "on",
        },
        {
          weight: "5.71",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#272a2c",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#1e1f22",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#161619",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#0e0f11",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#131415",
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#0c0c0e",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          lightness: 18,
        },
        {
          visibility: "on",
        },
        {
          color: "#ff0000",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#202224",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#141516",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#202224",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#141619",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#1b1c1f",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#17181b",
        },
      ],
    },
  ],
}

class MapEvent extends Component {
  static defaultProps = {
    center: {
      ...coordinates,
    },
    zoom: 14,
  }

  render() {
    return (
      <MapSection id="rocketshipHQmap">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCoduza6cuExz-BhaIntF7teOzc_dKB-LE" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={mapStyles}
        >
          <MapMarker
            lat={coordinates.lat}
            lng={coordinates.lng}
            text="Rocketship HQ"
          />
        </GoogleMapReact>
      </MapSection>
    )
  }
}

export default MapEvent
